(function($) {
  $(function() {
    var headerHeight = $('header').outerHeight() + 10;

    $('[data-header-nav-close]').on('click', function(evt) {
      $('#js-trigger').removeClass('is-active');
      $('body').removeClass('js-nav-open');

      var href= $(this).attr("href");
      var target = $(href);
      var position = target.offset().top - headerHeight;
      $('body,html').stop().animate({scrollTop:position}, 500);
    });

    var urlHash = location.hash;
    if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, 500);
      }, 200);
    }
  });
})(jQuery);
